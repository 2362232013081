.post-container {
    margin-top: 20px;
    min-width: 800px;
    max-width: 800px;
}

.post-container-with-active-report {
    background-color: indianred;
}

.post-container-deleted {
    background-color: #aaa;
}

.post-container-disabled-user {
    background-color: orange;
}

.post-container-pending-notification-review {
    background-color: wheat;
}

.post-top-container {
    display: flex;
    padding-bottom: 20px;
}

.post-user-image {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-right: 20px;
}

.post-top-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
}

.post-image-scroller {
    margin-left: -20px;
    margin-right: -20px;
    min-width: 840px;
    max-width: 840px;
    margin-bottom: 20px;
}

.post-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;
}

.post-title-pre {
    color: #06db89;
    font-weight: bold;
    margin-right: 10px;
}

.post-title-image {
    width: 30px;
    margin-right: 20px;
}

.post-content-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;
}

.post-content-row-icon.post-content-row-icon {
    width: 30px;
    font-size: 30px;
    margin-right: 20px;
    text-align: center;
}

.post-content-row-icon-second.post-content-row-icon-second {
    width: 30px;
    font-size: 30px;
    margin: 0 20px;
    text-align: center;
}

.post-content-row-text {}

.post-pin-dialog {
    background-color: lightcoral;
}