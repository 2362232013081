.user-detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    overflow: auto;
}

.user-report-container {
    padding-left: 0px;
    padding-right: 0px;
}
