.user-list-filter-container {
    margin: 20px;
    background-color: mistyrose;
    padding: 20px;
    display: flex;
    flex-direction: row;
    border: 1px solid #ddd;
}

.user-list-filter-stacked-options {
    padding-left: 20px;
}
