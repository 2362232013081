.chip-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 20px;
}

.chip-list-default {}

.chip-container {
    margin-right: 20px;
    margin-bottom: 10px;
    flex: 1;
}

.chip {
    display: flex;
    flex-direction: row;
    padding: 5px;
    border-radius: 12px;
    height: 24px;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(170, 170, 170, 1);
    -moz-box-shadow: 0px 1px 2px 0px rgba(170, 170, 170, 1);
    box-shadow: 0px 1px 2px 0px rgba(170, 170, 170, 1);
    cursor: pointer;
}

.chip-default {
    background-color: #999;
    color: #000;
}

.chip-deselected-default {
    background-color: #f2f2f2;
    color: #999;
}

.chip-unselected-default {
    background-color: #f2f2f2;
    color: #999;
}

.chip-with-icon {
    height: 34px;
    border-radius: 17px;
}