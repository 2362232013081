.post-detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    overflow: auto;
}

.post-detail-report-list {
    width: 800px;
    margin-top: 20px;
}
