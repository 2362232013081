.post-list-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
}

.post-list-list {
    overflow: auto;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
