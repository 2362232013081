.post-list-filter-container {
    margin: 20px;
    background-color: mistyrose;
    padding: 20px;
    display: flex;
    flex-direction: row;
    border: 1px solid #ddd;
}

.post-list-filter-stacked-options {
    padding-left: 20px;
}

.post-list-chip-container {}

.post-list-chip {
    background-color: #29f9a9;
    color: #7a7a7a;
}

.post-list-chip-unselected {
    background-color: #29f9a9;
    color: #7a7a7a;
}

.post-list-chip-deselected {
    background-color: #ddd;
    color: #7a7a7a;
}