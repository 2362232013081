.message-list {
    display: flex;
    flex-direction: column;
}

.message {
    border-radius: 5px;
    background-color: lightgrey;
    align-self: flex-start;
    padding: 5px;
}

.message-me {
    background-color: lightgreen;
    align-self: flex-end;
}
