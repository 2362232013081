.autocomplete-list-container {
    flex: 1;
    overflow: auto;
}

.autocomplete-list-table td {
    padding: 2px 10px;
}

.autocomplete-filter-container {
    flex-direction: row;
}
.autocomplete-filter-container .report-control {
    display: inline;
}