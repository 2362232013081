.body-container {
    flex: 1;
    flex-direction: column;
    display: flex;
    overflow: hidden;
}

.top-bar-container {
    background-color: #272930;
    display: flex;
}

.top-bar-item {
    padding: 10px 15px;
    color: #eee;
}

.main-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    background-color: yellow;
    overflow: hidden;
}

.left-container {
    flex: 0.2;
    min-width: 75px;
    max-width: 200px;
}

.content-container {
    background-color: #f2f2f2;
    flex: 1;
    display: flex;
    align-items: stretch;
}
