.comment-container {
    margin-top: 20px;
    min-width: 800px;
    max-width: 800px;
}

.comment-container-with-active-report {
    background-color: indianred;
}

.comment-container-deleted {
    background-color: #aaa;
}

.comment-container-disabled-user {
    background-color: orange;
}