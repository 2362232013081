.user-container {
    min-width: 800px;
    max-width: 800px;
    margin-top: 20px;
}

.user-container-with-active-report {
    background-color: indianred;
}

.user-container-disabled {
    background-color: orange;
}

.user-top-container {
    display: flex;
}

.user-image {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-right: 20px;
}

.user-top-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
}

.user-top-buttons {}

.report-control {
    margin-top: 20px;
}

.report-additional {
    border: 1px solid #ccc;
    padding: 10px;
    display: block;
    min-width: 600px;
    min-height: 100px;
}

.report-preview-header {
    margin-top: 20px;
    font-weight: bold;
}

.report-preview {
    border: 1px solid #ccc;
    padding: 10px;
    display: block;
    min-width: 600px;
    min-height: 300px;
}